/**
 * Scroll to content after filter
 * @param {string} selector
 * @param {number} delay
 */
export const scrollToSection = (selector, delay = 300) => {
    const content = document.querySelector(selector);
    setTimeout(() => {
        content.scrollIntoView({ behavior: 'smooth' });
    }, delay);
};
